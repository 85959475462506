@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Days+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Viga&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz@6..12&display=swap');


body{
  font-family: 'Nunito Sans', sans-serif;
 
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.navlinks{
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  color: #000;
  text-decoration: none;
  margin-right: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.herohead{
  font-family: 'Righteous', cursive;
}

.categorytitle{
  font-family: 'Days One', sans-serif;
}
.homepageheads{
  font-family: 'Montserrat', sans-serif;
}

.principaltitle{
  font-family: 'Viga', sans-serif;
}

.catfont{
  font-family: Lobster, cursive;
}
/* Add these styles to your main stylesheet (e.g., App.css) */
/* You may need to adjust these styles based on your design requirements */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: flex;
}

.slick-prev,
.slick-next {
  font-size: 24px;
  color: #000; /* Change the color as needed */
  z-index: 1;
}

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: #ff0000; /* Change the hover/focus color as needed */
  outline: none;
}


.desc{
  font-family: 'Lora', serif;
}

.card {
  width: 400px;
  height: 285px;
  background: #313131;
  border-radius: 20px;
  ;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  transition: 0.2s ease-in-out;
}

.img {
  height: 30%;
  position: absolute;
  transition: 0.2s ease-in-out;
  z-index: 1;
}

.textBox {
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  transition: 0.2s ease-in-out;
  z-index: 2;
}

.textBox > .text {
  font-weight: bold;
}

.textBox > .head {
  font-size: 20px;
}

.textBox > .price {
  font-size: 17px;
}

.textBox > span {
  font-size: 12px;
  color: lightgrey;
}

.card:hover > .textBox {
  opacity: 1;
}

.card:hover > .img {
  height: 65%;
  filter: blur(7px);
  animation: anim 3s infinite;
}

@keyframes anim {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

.card:hover {
  transform: scale(1.04) rotate(-1deg);
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.animated-gradient {
  background: linear-gradient(90deg, #B19470 0%, #ff9900 50%, #7E2553 50%, #0000ff 100%, #ff69b4 100%);
  background-size: 400% auto; /* Increase the size for a smoother transition */
  animation: gradientAnimation 10s linear infinite; /* Adjust the duration for a slower movement */
  -webkit-background-clip: text;
  color: transparent;
}


.fade-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.fade-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 4s ease-in-out; /* Adjust the transition duration */
}

.fade-item.active {
  opacity: 1;
}

.fade-item.hidden {
  display: none;
}

.fade-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
